.content-page.article{
	.article-body{
		position: relative;
		z-index: 2;
		max-width: 1660px;
		margin: 0 auto;
		padding: 20px 100px 0;

		.bloc{
			position: relative;
			margin-bottom: 18px;

			p{
				position: relative;
				z-index: 2;
				font-family: $regular;
				margin-bottom: 18px;

				strong, b{
					display: inline-block;
					margin-right: 10px;
					letter-spacing: -.2px;
					font-family: $bold;
				}

				a{
					text-decoration: underline;
				}
			}

			.wp-caption{
				width: 100% !important;
			}

			.wp-caption-text{
				margin-bottom: 10px 0 18px;
				font-size: 14px; line-height: 16px;
				letter-spacing: -.3px;
				opacity: .6s;
			}

			.iframe-container{
				position: relative;
				@include aspect-ratio(16, 9);
				margin-bottom: 20px;
			}

			iframe{
				position: absolute;
				top: 0; left: 0;
				width: 100%; height: 100%;
			}

			.media{
				position: relative;
				width: 100%;
				margin-left: auto;
				margin-right: auto;
				z-index: 0;
				
				img{
					display: block;
					width: 100%;
				}

				&.portrait{
					width: 45%;
					margin: 0 auto 0 35%;
				}
			}

			.quote{
				position: absolute;
				top: 0;
				width: 32%;
				z-index: 1;
				font-family: $regular;
				font-size: 30px;
				line-height: 34px;
				letter-spacing: -.2px;

				padding: 0;

				&.credits{
					font-size: 19px;
					line-height: 23px;
					letter-spacing: .1px;

					.line
					{
						margin-bottom: 20px;
						span{
							display: block;
						}
					}
				}

				.video-container{
					position: relative;
					width: 100%;
					@include aspect-ratio(16, 9);

					iframe{
						position: absolute;
						top: 0; left: 0;
						width: 100%;
						height: 100%;
					}
				}

				.media{
					width: 100%;
				}

				&.serif{
					font-family: $regular;
				}

				&.middle{
					top: 50%;
					transform: translateY(-50%);
				}

				&.bottom{
					top: auto;
					bottom: 0;
				}

				&.right{
					padding-left: 3%;
					right: 0;
					width: 20%;
				}
			}


			&.central-bloc{
				.main-txt{
					width: 45%;
					margin: 0 auto 0 35%;

					img{
						width: 100%;
						height: auto;
					}
				}
			}


			// &.diptyque-bloc{
			// 	.col.quote{
			// 		padding-right: 3%;
			// 	}

			// 	.main-txt{
			// 		width: 45%;
			// 		margin-left: 35%;
			// 	}

			// 	.quote{
			// 		left: 0;
			// 		width: 35%;
			// 	}
			// }

			&.fullsize-bloc{
				.quote{
					position: relative;
					width: 100%;
					font-size: 60px;
					line-height: 60px;
				}

				&.left-margin{
					padding-left: 35%;
				}
			}

			&:last-of-type{
				margin-bottom: 0;
			}
		}
	}
}





////////////////////////////////////////////////////////////////
///////////////////////// MEDIAQUERIES /////////////////////////
////////////////////////////////////////////////////////////////


@media 	only screen  and (max-width: $xsMobile) {
	.content-page.article{
		padding: 24	0px 0;

		.article-body{
			padding: 20px 10px 0;

			.bloc{
				.iframe-container{
					position: relative;
					@include aspect-ratio(16, 9);
					margin-bottom: 18px;
				}

				.media{
					position: relative;
					width: 100%;
					margin-left: auto;
					margin-right: auto;
					z-index: 0;
					
					img{
						display: block;
						width: 100%;
					}

					&.portrait{
						width: 45%;
						margin: 0 auto 0 35%;
					}
				}

				.quote{
					position: relative;
					width: 100%;
					font-size: 30px;
					line-height: 34px;
					letter-spacing: -.2px;
					margin-bottom: 10px;

					iframe{
						position: relative;
					}

					&.credits{
						overflow: hidden;

						.line
						{
							width: 50%;
							float: left;
						}
					}

					.media{
						width: 100%;
					}

					&.serif{
						font-family: $regular;
					}

					&.middle{
						top: 0;
						transform: none;
					}

					&.bottom{
						top: auto;
						bottom: auto;
					}

					&.right{
						padding-left: 0;
						right: auto;
						left: 0;
						width: 100%;
					}
				}


				&.central-bloc{
					margin-bottom: 18px;
					.main-txt{
						width: 100%;
						margin: 0 auto;
					}
				}

				&.fullsize-bloc{
					.quote{
						font-size: 30px;
						line-height: 34px;
						letter-spacing: -.2px;
					}

					&.left-margin{
						padding-left: 0;
					}
				}
			}
		}
	}
}