#fixed-footer{
	position: fixed;
	bottom: 0;
	width: 100%;
	opacity: 0;
	visibility: hidden;
	transition: opacity .2s ease;

	.logo{
		position: absolute;
		bottom: 0;
		display: block;
		width: 60px; height: 70px;
		transition: all .2s ease;

		&.right{
			    right: 15px;
			    height: 60px;
			    width: 40px;
		}

		&.left{
			width: 60px;
			left: 15px;
			height: 50px;
		}

		svg{
			fill: $black;
			// height: 100%;
		}
	}

	&.scrolled{
		opacity: 1;
		visibility: visible;
	}
}

.page-footer{
	position: relative;
	width: 100%;
	overflow: hidden;
	padding-bottom: 26px;
	margin: 140px auto 0;

	.elt{
		display: block;
		font-family: $regular;
		width: 21%;
		text-align: left;
		float: left;

		&:first-of-type{
			width: 35%;
		}
	}
}



////////////////////////////////////////////////////////////////
///////////////////////// MEDIAQUERIES /////////////////////////
////////////////////////////////////////////////////////////////

@media 	only screen  and (max-width: $mobileMaxWidth) {
	#fixed-footer, .page-footer{
		display: none !important;
	}
}