#main-header{
	position: fixed;
	width: 100%;
	overflow: hidden;
	-webkit-transform: translateZ(0);
	padding: 8px 16px 0px;
	color: $black;
	text-align: center;
	z-index: 998;

	.elt{
		display: inline-block;
		vertical-align: middle;
		font-family: $regular;
		// transition: all .3s ease;
		visibility: visible;
		text-align: left;

		&.left{
			float: left;
		}

		&.right{
			float: right;
			text-align: right;
		}

		&.btn{
			position: relative;
			padding-bottom: 4px;
			
			&:after{
				content:"";
				position: absolute;
				bottom: 0; left: 0;
				display: block;
				width: 100%; height: 1px;
				background: inherit;
				opacity: 0;
			}	
			
			&:hover{
				&:after{
					opacity: 1;
				}
			}
		}

		&.close-btn{
			position: absolute;
			opacity: 0;
			visibility: hidden;
   			right: 45px;
   			// color: $white !important;
		}

	}
}

#mobile-menu{
	display: none;
	position: fixed;
	z-index: 1000;
	width: 100%;
	// transition: all .5s ease .3s;

	// overflow: hidden;

	.top{
		position: absolute;
		top: 0; left: 0;
		z-index: 2;
		width: 100%; 
		height: 60px;
		background: $white;
	}

	.bottom{
		position: absolute;
		top: -10px; left: 0;
		z-index: 1;
		display: block;
		width: 100%; height: 100vH;
		background: $white;
		opacity: 0;
		visibility: hidden;
		transition: all .2s $easeOutQuad;
	}

	.logo{
		position: relative;
		display: block;
		height: 60px; width: auto;
		padding: 10px 15px;
		float: left;

		svg{
			height: 100%;
			fill: $black;
		}
	}

	.btn-menu{
		position: relative;
		float: right;
		width: 40px; height: 60px;
		margin-right: 15px;

		.bar{
			position: absolute;
			width: 40px; height: 2px;
			top: 49%;
			background: $black;
		
			&:before, &:after{
				content: "";
				position: absolute;
				width: 100%; height: 2px;
				background: $black;
			}

			&:before{
				top: -9px;
			}

			&:after{
				top: 9px;
			}
		}
	}

	.links{
		position: absolute;
		left: 10px; bottom: 10px;
		font-size: 40px;
		line-height: 38px;

		.group{
			margin-top: 30px;

			a{
				display: block;
			}
		}
	}


	&.opened{
		// height: 100%;
		.bottom{
			top: 0;
			opacity: 1;
			visibility: visible;
		}

		.btn-menu{
			height: 40px;
			.bar{
				margin-top: 8px;
				transform: rotate(-45deg);

				&:before{
					top: 0;
					transform: rotate(90deg);
				}

				&:after{
					display: none;
				}
			}
		}

		.links{
			display: block;
		}
	}
}


////////////////////////////////////////////////////////////////
///////////////////////// MEDIAQUERIES /////////////////////////
////////////////////////////////////////////////////////////////

@media 	only screen  and (max-width: $xsMobile) {
	#main-header{
		display: none;
	}

	#mobile-menu{
		display: block;
		opacity: 0; 
		visibility:hidden;
		transition: opacity .3s ease;


		&.scrolled{
			opacity: 1; 
			visibility:visible;
		}
	}
}