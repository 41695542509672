/* Mixins */

//Hide text @extend %hide_text; to call
%hide_text{
    text-indent:-9999px;
    color:transparent;
}

%truncate_text{
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
}

//Clean "clear"
@mixin clearfix() {
  *zoom: 1;

  &:before,
  &:after {
    display: table;
    content: "";
  }
  &:after {
    clear: both;
  }
}

//center verticaly the element in the context of the last relative parent element.
@mixin centerElement($height : 0, $width : 0){

    @if($height != 0 or $width != 0){
        position:absolute;
    }

    @if($height != 0){
      top:50%;
      margin-top: - $height/2;
    }

    @if($width != 0){
      left:50%;
      margin-left: - $width/2;
    }
}

//Create triangle
@mixin triangle($point, $size, $color){
  $opposite : opposite-position($point);
  border : $size solid transparent;
  border-#{$opposite} : $size solid $color;
  border-#{$point} : 0;
  height:0;
  width:0;
}

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-ms-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}

//Ex : create keyframe:
// @include keyframes (loading) {
//    from { max-width: 0; }
//}

@mixin animate($animation) {
  -webkit-animation: #{$animation};
  -moz-animation: #{$animation};
  -ms-animation: #{$animation};
  animation: #{$animation};
}

//Ex: ANimate keyframe
// @include animate(loading 10s linear infinite);

//Sprite function
@function sprite($top: 0, $left: 0, $file: 'sprite.png') {
    @return image-url($file) no-repeat $top $left;
}

// Ems calculator
@function em($px: $fontBase, $base: $fontBase) {
  @return #{$px/$base}em;
  //@return $px;
}

@mixin circle($width) {
  width: $width;
  height: $width;
  -webkit-border-radius: $width/2;
     -moz-border-radius: $width/2;
          border-radius: $width/2;
}

@mixin bodycolor($color){
  background-color: $color;
  color: $color;
  border-color: $color;

  *::-moz-selection {
      background: $color;
  }
  *::selection {
      background: $color;
  }
}


@mixin blendMode($color, $mode){  
    background-color: $color;
    background-blend-mode: $mode;
    -ms-background-blend-mode: $mode;
    -o-background-blend-mode: $mode;
    -moz-background-blend-mode: $mode;
    -webkit-background-blend-mode: $mode;
}

@mixin box-sizing($bs) {
  $bs: unquote($bs);
  @include experimental(box-sizing, $bs, -moz, -webkit, not -o, not -ms, not -khtml, official);
}

@mixin box-shadow($top, $left, $blur, $color) {
  -webkit-box-shadow:$top $left $blur $color;
  -moz-box-shadow:$top $left $blur $color;
  box-shadow:$top $left $blur $color;
}

@mixin input-placeholder($font, $color, $size) {
  &::-webkit-input-placeholder {
    font-family: $font;
    color: $color;
    font-size: $size;
  }
  &:-moz-placeholder {
    font-family: $font;
    color: $color;
    font-size: $size;
  }
  &:-ms-input-placeholder {
    font-family: $font;
    color: $color;
    font-size: $size;
  }
}

// generic transform
@mixin transform($transforms) {
	   -moz-transform: $transforms;
	     -o-transform: $transforms;
	    -ms-transform: $transforms;
	-webkit-transform: $transforms;
          transform: $transforms;
}
// rotate
@mixin rotate ($deg) {
  @include transform(rotate(#{$deg}deg));
}

// scale
@mixin scale($scale) {
	 @include transform(scale($scale));
}
// translate
@mixin translate ($x, $y) {
   @include transform(translate($x, $y));
}
// skew
@mixin skew ($x, $y) {
   @include transform(skew(#{$x}deg, #{$y}deg));
}
//transform origin
@mixin transform-origin ($origin) {
      moz-transform-origin: $origin;
	     -o-transform-origin: $origin;
	    -ms-transform-origin: $origin;
	-webkit-transform-origin: $origin;
          transform-origin: $origin;
}

// transitions
@mixin transition($args) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

@mixin animationDelay($delay) {
  -webkit-transition-delay: $delay;
  -moz-transition-delay: $delay;
  -ms-transition-delay: $delay;
  -o-transition-delay: $delay;
  transition-delay: $delay;
}


// placeholder
@mixin placeholder {
  ::-webkit-input-placeholder {@content}
  :-moz-placeholder           {@content}
  ::-moz-placeholder          {@content}
  :-ms-input-placeholder      {@content}  
}


/*

Aspect ratio mixin. can accept width and height or just the ratio

Relies on a floated element's padding

found at http://codepen.io/jakob-e/pen/LEdWNB?editors=110 linked to from https://css-tricks.com/snippets/sass/maintain-aspect-ratio-mixin/

*/

@mixin aspect-ratio($ratio-or-width, $height: null) {
    $padding: if($height, percentage($height/$ratio-or-width), percentage(1/$ratio-or-width));
    &:before { content:''; float: left; padding-bottom: $padding;  }
    &:after  { content:''; display: table; clear: both;  }
}


/*END Mixins */
