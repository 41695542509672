.btn-close{
	width: 22px; height: 22px;
	transition: all .2s ease;

	&:after, &:before{
		content: "";
		position: absolute;
		display: block;
		left: 0; top: 50%;
		width: 100%; height: 2px;
		background-color: $white;
	}

	&:after{
		transform: rotate(45deg);
	}

	&:before{
		transform: rotate(-45deg);
	}

	&:hover{
		opacity: .5;
	}
}