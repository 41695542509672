#listing{
	position: fixed;
	top: 0; left: 0;
	width: 100%; height: 100%;
	background: $black;
	z-index: 2000;
	color: $white;
	overflow-x: hidden;
	overflow-y: scroll;
	transition: all .3s ease;
	-webkit-overflow-scrolling: touch;

	.artists-list{
		position: relative;
		margin: 15px 20px;

		.col{
			width: 28%;
			max-width: 350px;
			float: left;

			a{
				// display: block;
				font-size: 26px;
				line-height: 28px;
			}
		}
	}

	.artist-back{
		position: fixed;
		bottom: 15px; left: 20px;
		height: 50%;
		opacity: 0;
		// transition: all .3s ease;

		img{
			position: relative;
			display: block;
			height:100%;
		}

		&.active{
			opacity: 1;
		}
	}

	.artist-intro{
		position: fixed;
		bottom: 50%; left: 20px;
		width: 90%;
		margin-bottom: 20px;
		opacity: 0;
		line-height: 20px;
		color: $white;

		&.active{
			opacity: 1;
		}
	}

	&.active{		
		opacity: 1;
		visibility: visible;
		width: 100%; left: 0;

		.listing-header, .sidebar{
			opacity: 1; visibility: visible;
		}
	}

	.btn-close{
		position: absolute;
		right: 20px; top: 15px;
		z-index: 20;
	}

	.logo{
		position: absolute;
		bottom: 0px; right: -15px;
		display: block;
		width: 70px; height: 60px;

		svg{
			height: 70%;
			fill: $white;
		}
	}
}





////////////////////////////////////////////////////////////////
///////////////////////// MEDIAQUERIES /////////////////////////
////////////////////////////////////////////////////////////////

@media 	only screen  and (max-width: $mobileMaxWidth) {
	#listing{
		// padding-top: 60px; 

		.btn-close{
			position: fixed;
			right: 10px;
		}

		.artists-list{
			margin: 10px 10px;
			.col{
				width: 100%;
			}
		}

		.artist-back, .artist-intro{
			display: none;
		}

		.logo{
			bottom: -10px;
    		right: -20px;
		}
	}
}