.content-page.about{
	background: $red;
	padding: 0 100px;

	.to-center{
		max-width: 1200px;
	}

	.introduction{
		margin-bottom: 0;
	}

	.cover{
		width: 66%;

		img{
			display: block;
			width: 100%;
		}
	}

	.text{
		max-width: 1000px;
		margin-bottom: 140px;

		h1, p{
			font-family: $regular;
			font-size: 30px; 
			margin-bottom: 28px; 
			line-height: 32px; 
			letter-spacing: -.1px; 
		}
	}

	.cols-container{
		overflow: hidden;

		.col{
			width: 32%;
			float: left;
			line-height: 20px;

			&:first-of-type{
				width: 35%;
			}
		}
	}
}





////////////////////////////////////////////////////////////////
///////////////////////// MEDIAQUERIES /////////////////////////
////////////////////////////////////////////////////////////////

@media 	only screen  and (max-width: $mobileMaxWidth) {
	.content-page.about{
		padding: 0;

		.cover{
			width: 100%;

			img{
				display: block;
				width: 100%;
			}
		}

		.text{
			margin-bottom: 40px;
			padding: 0 10px;

			h1, p{
				font-size: 24px; 
				margin-bottom: 20px; 
				line-height: 26px; 
				letter-spacing: -.4px; 
			}
		}

		.cols-container{
			padding: 0 10px;

			.col{
				float: none;
				width: 100%;
				margin-bottom: 40px;

				&:first-of-type{
					width: 100%;
				}
			}
		}
	}
}