//Variables

$fontBase			: 16;
$arial          	: Arial, "Helvetica Neue", Helvetica, sans-serif; 
//
$regular 			: 'Appel-Book';
$bold 				: 'Appel-Medium';
//
$serif            	: Arial;
$serifLight         : Arial;
$light           	: Arial;


@font-face {
	font-family: 'Appel-Book';
	src: url('../fonts/Appel-Book.eot');
	src: local('☺'), url('../fonts/Appel-Book.woff') format('woff'), url('../fonts/Appel-Book.ttf') format('truetype'), url('../fonts/Appel-Book.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}



@font-face {
	font-family: 'Appel-Medium';
	src: url('../fonts/Appel-Medium.eot');
	src: local('☺'), url('../fonts/Appel-Medium.woff') format('woff'), url('../fonts/Appel-Medium.ttf') format('truetype'), url('../fonts/Appel-Medium.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}









/*
$serif            	: 'SectraBook';
$serifLight         : 'SectraLight';
$regular           	: 'FondersGroteskRegular';
$light           	: 'FondersGroteskLight';



@font-face {
	font-family: 'FondersGroteskRegular';
	src: url('../fonts/FoundersGroteskRegular.eot');
	src: local('☺'), url('../fonts/FoundersGroteskRegular.woff') format('woff'), url('../fonts/FoundersGroteskRegular.ttf') format('truetype'), url('../fonts/FoundersGroteskRegular.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'FondersGroteskLight';
	src: url('../fonts/FoundersGroteskLight.eot');
	src: local('☺'), url('../fonts/FoundersGroteskLight.woff') format('woff'), url('../fonts/FoundersGroteskLight.ttf') format('truetype'), url('../fonts/FoundersGroteskLight.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'SectraBook';
	src: url('../fonts/GTSectraFineBook.eot');
	src: local('☺'), url('../fonts/GTSectraFineBook.woff') format('woff'), url('../fonts/GTSectraFineBook.ttf') format('truetype'), url('../fonts/GTSectraFineBook.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'SectraLight';
	src: url('../fonts/GTSectraDisplayLight.eot');
	src: local('☺'), url('../fonts/GTSectraDisplayLight.woff') format('woff'), url('../fonts/GTSectraDisplayLight.ttf') format('truetype'), url('../fonts/GTSectraDisplayLight.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}
*/