.content-page.error{
	padding: 0 100px;
	height: 100vh;

	.to-center{
		max-width: 1460px;
	}

	.page-footer{
		position: absolute;
		bottom: 0;
	}

	.text{
		h1, p{
			font-family: $bold;
			font-size: 30px; 
			margin-bottom: 28px; 
			line-height: 32px; 
			letter-spacing: -1px; 
		}
	}
}





////////////////////////////////////////////////////////////////
///////////////////////// MEDIAQUERIES /////////////////////////
////////////////////////////////////////////////////////////////

@media 	only screen  and (max-width: $mobileMaxWidth) {
	.content-page.error{
		padding: 10px 0px;
		height: 100vh;
	}
}