html { -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; }
body { margin: 0; padding:0; -webkit-overflow-scrolling: touch; }


/* -------------------------------------------- */
/* ----  UTILS */
/* -------------------------------------------- */
.ir {display: block; border: 0; text-indent: -999em; overflow: hidden; background-color: transparent; background-repeat: no-repeat;  direction: ltr; *line-height: 0; }
.ir br { display: none; }
.hidden { display: none !important; visibility: hidden; }
.fade-hidden { opacity: 0; display: none !important; }
.fade { opacity: 0; visibility: hidden; }
.visuallyhidden { border: 0; clip: rect(0 0 0 0); height: 1px; margin: -1px; overflow: hidden; padding: 0; position: absolute; width: 1px; }
.visuallyhidden.focusable:active, .visuallyhidden.focusable:focus { clip: auto; height: auto; margin: 0; overflow: visible; position: static; width: auto; }
.invisible { visibility: hidden; }
.clearfix:before, .clearfix:after { content: ""; display: table; }
.clearfix:after { clear: both; }
.clearfix { *zoom: 1; }
.clear { clear : both; }



/**************************************************************************************/
/**************************************************************************************/
/**************************************************************************************/
/**************************************************************************************/
/**************************************************************************************/
/**************************************************************************************/


body
{
	position: relative;
	width: 100%; height: 100%;
	padding:0;
	font-family: $regular;
	line-height: 1.4;
	font-size: 18px;
	overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6
{
	font-weight: normal;
}

a{
	color:inherit;
	text-decoration: none;

	&.classic-link{
		transition: all .2s ease;

		&:hover{
			opacity: .5;
		}
	}
}

b, strong
{
	// font-family: $bold;
}

i
{
	// font-family: $italic;
	font-style: italic;
}

p{
	font-size: 19px;
	line-height: 23px;
	letter-spacing: .1px;
}


#main-container
{
	position:relative;
	top: 0; left:0;
	display: block;
	width:100%;
	overflow-x: hidden;
}

.content-to-center{
	width: 960px;
	margin: 0 auto;
}

.keepImgRatio{
	position: absolute;
	transition: all .3s ease;
}






////////////////////////////////////////////////////////////////
///////////////////////// MEDIAQUERIES /////////////////////////
////////////////////////////////////////////////////////////////

@media 	only screen  and (max-width: $ipad) {
	p{
		font-size: 18px;
		line-height: 26px;
	}
}

@media 	only screen  and (min-width: $xsMobile) {
	body{
		.not-on-desktop{
			display: none !important;
		}
	}
}

@media 	only screen  and (max-width: $xsMobile) {
	body{
		.not-on-mobile{
			display: none !important;
		}
	}
}

@media 	only screen  and (max-width: $xsMobile) {
	p{
		font-size: 16px;
		line-height: 24px;
	}
}