.content-page.home{
	margin: 0 auto;
	padding: 0px 100px;

	.to-center{
	max-width: 1200px;
	}

	.grid-articles{
		width: 100%;
		overflow: hidden;

		.grid-sizer{
			width: 33.33%;
		}

		.line{
			display: flex;
		}
	}

	.btn-article{
		display: block;
		width: 30%;
		float: left;
		padding-bottom: 20px;
		overflow: hidden;
		transition: all .3s ease;
		opacity: 1;
		margin: 0px 3% 20px 0px;
		overflow: hidden;
		border-top: 1px solid $black;

		a{
			// display: block;
		}	

		.date{
			font-size: 12px;
		}

		figure{
			position: relative;
			display: block;
			// max-height: 400px;
			/* margin-bottom: 3px; */
			transition: all .2s ease;
			overflow: hidden;
			margin: 10px 0;
			height: 300px;

			img{
				display: inline-block;
				height: 100%;
			}

			&.align-right{
				text-align: right;
			}
		}

		.intro{
			display: block;
			font-size: 16px; line-height: 18px; letter-spacing: .4px;
		}

		.title{
			position: relative;
			display: inline-block;
			width: 60%;
			font-size: 36px;
			line-height: 31px;
			letter-spacing: -.2px;
			margin-bottom: 3px;
		}

		&.news{
			figure{
				background-color: #ff0000;

			    .to-process{	
		    		position: absolute;
		    		top: 0; left: 0;
		    		display: block;
		    		width: 100%; height: 100%; 
			  		filter: invert(1) grayscale(100%);
			  		mix-blend-mode: screen;
				    background-size: cover;
				    background-position: center center;
				    background-repeat: no-repeat;
			    }
			}
		}
	}
}






////////////////////////////////////////////////////////////////
///////////////////////// MEDIAQUERIES /////////////////////////
////////////////////////////////////////////////////////////////

@media 	only screen  and (max-width: $desktopSmall) {
	.content-page.home .grid-articles{
		.grid-sizer{
			width: 50%;
		}

		.btn-article{
			width: 47%;
			margin: 0px 3% 20px 0px;
		}
	}
}

@media 	only screen  and (max-width: $xsMobile) {
	.content-page.home {
		padding: 0;

		.grid-articles{

			.line{
				display: block;
			}

			.btn-article{
				width: auto;
				padding: 0;
				margin: 0 10px 30px;

				.intro, .title{
				}

				.title{
					padding-top: 0px;
				}

				&.news{
					padding: 0px;
					.intro, .title{
						padding: 0px;
					}
				}
			}
		}
	}
}