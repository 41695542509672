#main-container .logo-page{
	position: absolute;
	top: 10px; left:50%;
	z-index: 1000;
	transform: translateX(-50%);
	display: block;
	width: 150px; height: 120px;

	&.home{
		position: relative;
		transform: none; 
		top: 7px; left: 0;
		width: 100%; height: auto;
		@include aspect-ratio(1440, 310);

		svg{
			position: absolute;
			top: 0; left: 0;
		}
	}
}

.content-page{
	position: relative;
	width: 100%;
}

.to-center{
	position: relative;
	margin: 0 auto;
}

.article-header, .introduction{
	position: relative;
	display: block;

	.to-center{
		z-index: 2;
		height: 100%;
	}

	.illus{
		position: relative;
		display: block;
		z-index: 0;

		img{
			position: relative;
			display: inline-block;
			width: 40%;
		}
	}

	.intro{
		position: relative;
		z-index: 2;
		font-size: 19px; line-height: 22px; letter-spacing: .1px;
		margin: 0;
		opacity: 1; 
		margin-bottom: 5px;
	}
}


.article-header{
	height: 100vh;
	overflow: hidden;

	.page-title{
		position: absolute;
		bottom: 5px; left: 100px;
		z-index: 2;
		width: 85%;
		padding: 0px 0 13px;
		font-family: $bold;
		font-size: 160px; 
		line-height: 130px; 
		letter-spacing:-8px;
	}

	.intro{
		margin-bottom: 10px;
	}

	.illus{
		margin-bottom: 10px;

		img{
			position: relative;
			display: block;
			max-height: 100%;
			width: auto;
		}
	}

	.to-center{
		max-width: 1660px;
		padding: 80px 100px 0;
	}

	&.type-2{
		.illus{
			margin-bottom: 5px;
			img{
				position: absolute;
				bottom: 0;
				right: 0;
				max-height: 80%;
			}
		}
	}

	&.type-3{
		.page-title{
			position: relative;
			left: auto; right: auto;
		}
	}

	&.type-4{
		.illus{
			width: 60%;
			img{
				width: 100%;
				height: auto;
			}
		}
	}
}



.introduction{
	height: auto;
	margin-bottom: 80px;

	.to-center{
		max-width: 1400px;
	}

	.page-title{
		position: relative;
		z-index: 1;
		font-family: $regular;
		font-size: 48px;
		line-height: 40px;
		letter-spacing: -.2px;
		margin-bottom: 5px;
	}

	.intro{
		display: block;
	}

	.illus{
		width: 40%;

		img{
			width: 100%;
		}

		&.landscape{
			width: 65%;
		}
	}
}





////////////////////////////////////////////////////////////////
///////////////////////// MEDIAQUERIES /////////////////////////
////////////////////////////////////////////////////////////////

@media 	only screen  and (max-width: $xsMobile) {
	#main-container .logo-page{
		position: relative;
		transform: none; 
		top: 0px; left: 0;
		width: 100%; height: auto;
		transform: none;
		@include aspect-ratio(1440, 600);

		svg{
			position: absolute;
			top: 0; left: 0;
		}

		&.home{
			@include aspect-ratio(1440, 500);
			// top: 0;
			top: 10px;
			margin-bottom: 10px;
		}
	}

	.article-header, .introduction{
		height: auto;
		padding: 0 10px;

		.to-center{
			padding: 0;
		}

		.page-title{
			position: relative;
			z-index: 1;
			font-family: $bold;
		}

		.illus{
			img{
				width: 100% !important;
			}

			&.landscape{
				width: 100%;
			}
		}
	}

	.article-header{
		.to-center{
			height: auto;
		}

		.page-title{
			position: relative;
			bottom: auto; left: auto;
			font-size: 50px; line-height: 40px; letter-spacing:-2px;
			padding: 20px 0;
		}

		.illus{
			position: relative !important;
			width: 100%;
			height: auto !important;
			margin-bottom: 0 !important;

			img{
				position: relative !important;
				height:  auto !important;
			}
		}
	}

	.introduction{
		padding: 0 10px;
		.illus{
			width: 100%;
		}

		// .page-title, .intro{
		// }
	}
}